import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Lodash from 'lodash'
import {ArrayUtil} from 'helper-util'
import {paths, protectedRouteList} from '../../routes/routesConfig'
import FsLink from '../../common/fsLink'
import {
	IconRightMenuOpen,
	IconAccontSettings,
	IconChangePassword,
	IconProfile,
	SelectedFiles,
	ForwardBackwardIcon,
} from '../../styles/svg/svgIcons'
import {IconLogout, ButtonCheck, NightMode} from '../../styles/svg/svgIcons'
import {MenuFinoptsysLogo, MenuFinoptsysLogoHorizontal} from '../../styles/svg/svgIcons'
// import {IconProfile, IconCustomizeMenu, IconRightMenuClose} from '../../styles/svg/svgIcons'
import {history} from '../../store/mainStore'
import {activeClientConfig, getUsername, isAuthenticated} from '../../services/authService'
import {isInternalUser} from '../../services/authService'
import {Props, State} from './navigationBarModel'
import {notificationRoutes, notificationLabels, navigationMenu} from './navigationBarConstant'
import {checkFeaturePermission, checkRouteArrayPermissions, checkRoutePermission} from '../../utils/featureUtils'
import {getDefaultPath, getThemeFromLs, setFlexMonsterTheme} from '../../utils/featureUtils'
import {getMenuOptions, getMenuOptionsCount, getNavBarConfig, getUserName} from './navigationBarHelper'
import {isExecutionNotificationPermission, getFilteredPermissionMenu} from './navigationBarHelper'
import CustomiseMenu from '../customiseMenu/customiseMenuContainer'
import {getDefaultAvailableMenuOptions} from '../customiseMenu/customiseMenuHelper'
import {permission} from '../../constants/permission'
import {CLIENT_CONSTANTS, Config} from '../../constants/clientConfig'
import {RouteConfig} from '../../routes/routesConfig'
import FsOnOffSwitch from '../../common/formItems/fsOnOffSwitch'
import {genericLogout} from '../../app/appHelper'
import Homepage from './Homepage/HomepageContainer'
import {getStringFromStorage} from '../../services/storageService'

const Route = props => {
	const {goTo, activePath, label, path, icon, count, wideNavbar} = props
	const {toggleNavbarType, executionNotifications, navBarType, scrollLevel} = props
	const modfiedActivePath = activePath === paths.dashboard ? paths.dashboardTest : activePath // TEMP-FIX
	const isGreyed = path === '/simm'
	const isBeta = path === '/decision-making'
	const defaultPath = getDefaultPath(activePath, path)
	const isMainMenu = navBarType === 'main'

	return isMainMenu ? (
		<div
			onClick={() => {
				wideNavbar && toggleNavbarType()
				goTo(path)
			}}
			className={`nav-item ${modfiedActivePath === path || defaultPath ? 'active' : ''} ${
				isGreyed ? 'greyed-out' : ''
			}`}
			title={label}
		>
			{icon}
			{isMainMenu && <FsLink label={label} />}
			{isBeta && <span className='badge text-yellow f-9 mx-2 px-2 py-1 rounded-circle text-grey-11'>Beta</span>}
			{notificationElement(path, executionNotifications, 'path', wideNavbar)}
		</div>
	) : (
		<div className='menu-icon-wrapper'>
			<div
				onClick={() => goTo(path)}
				className={`nav-item ${modfiedActivePath === path || defaultPath ? 'active' : ''} ${
					isGreyed ? 'greyed-out' : ''
				}`}
			>
				<span className='fav-icon'>{icon}</span>
			</div>
			<span className='menu-icon-text' style={{top: `${135 + (count - scrollLevel) * 57}px`}}>
				{label}
			</span>
		</div>
	)
}

const SubRoute = props => {
	const {goTo, activePath, route, icon, wideNavbar, toggleNavbarType, executionNotifications} = props
	const [isActive, setIsActive] = useState(route.subMenu.some(sm => sm && sm.path === activePath))

	useEffect(() => {
		const {wideNavbar} = props
		!wideNavbar && setIsActive(wideNavbar)
		// eslint-disable-next-line
	}, [wideNavbar])

	return (
		<>
			<div
				onClick={() => {
					!wideNavbar && toggleNavbarType()
					setIsActive(!isActive)
				}}
				className={`nav-item has-submenu ${isActive ? 'active' : ''}`}
				title={route.label}
			>
				<span>{icon}</span>
				<span className='ml-3 f-14 text-uppercase overflow-ellipsis max-width-120 pr-2'>{route.label}</span>
				{notificationElement(route.label, executionNotifications, 'label', wideNavbar)}
			</div>
			<div className={`nav-item submenu ${isActive ? 'active' : ''}`} title={route.label}>
				{route.subMenu.map((sm: RouteConfig) => {
					const isGreyed = sm && sm.path === '/simm'
					const isBeta = sm && sm.path === '/decision-making'
					const defaultPath = getDefaultPath(activePath, sm.path)
					return (
						<div
							key={sm.path}
							className={`sub-item d-block overflow-ellipsis max-width-160 ${defaultPath ? 'active' : ''} ${
								isGreyed ? 'greyed-out' : ''
							}`}
							onClick={() => {
								wideNavbar && toggleNavbarType()
								if (sm.id !== 'group') {
									const routeDetail = Object.values(protectedRouteList).find(item => item.path === sm.path)
									const hasRouteAccess = checkRoutePermission(routeDetail)

									!hasRouteAccess
										? history.push(paths.accessDenied, {
												pageTitle: sm.label,
										  })
										: goTo(sm.path)
								}
							}}
							title={sm.labelTooltip || sm.label}
						>
							{sm.isAggregate ? <span className='pl-3'>{sm.label}</span> : sm.label}
							{isBeta && <span className='badge beta-badge f-9 mx-2 px-2 py-1 rounded-circle'>Beta</span>}
							{notificationElement(sm.path, executionNotifications, 'path', wideNavbar)}
						</div>
					)
				})}
			</div>
		</>
	)
}

const notificationElement = (path, data, key, wideNavbar) => {
	if (key === 'path') {
		return notificationRoutes
			.filter(item => item.path === path)
			.map(item => {
				return (
					data &&
					data[item.id] > 0 && (
						<span className='execution-count' key={item.id}>
							{data[item.id]}
						</span>
					)
				)
			})
	} else {
		return notificationLabels
			.filter(item => item === path)
			.map(item => {
				return (
					data &&
					data['total'] > 0 && (
						<span
							className={`${wideNavbar ? 'label-notification' : 'label-notification-collapsed'} execution-count`}
							key={path}
						>
							{data['total']}
						</span>
					)
				)
			})
	}
}
export default class NavigationBar extends React.Component<Props, State> {
	isIntlUser: boolean
	username: string
	clientConfig: any
	isTenantSelectionAllowed: any

	constructor(props) {
		super(props)
		const {activeMenuData, navBarType} = props
		const menuOptions = !Lodash.isEmpty(activeMenuData) ? getMenuOptions(activeMenuData) : []
		const defaultMenuOptions = getDefaultAvailableMenuOptions()
		this.isIntlUser = isInternalUser()
		this.username = getUsername()

		this.isTenantSelectionAllowed = JSON.parse(getStringFromStorage('tenantPageAccessible')) === true

		this.clientConfig = getStringFromStorage(activeClientConfig)
		if (this.clientConfig) {
			Config.setActiveConfig = this.clientConfig
		}

		const navBarConfig =
			navBarType === 'main'
				? navigationMenu[Config.activeConfig.id]
				: ArrayUtil.isEmpty(menuOptions)
				? getNavBarConfig(defaultMenuOptions)
				: getNavBarConfig(menuOptions)
		this.state = {
			showProfile: false,
			showCustomiseMenu: false,
			menuOptions,
			navBarConfig,
			scrollLevel: 0,
		}
	}

	componentDidMount() {
		const {
			setDarkTheme,
			getExecutionNotifications,
			setSuggestions,
			getLocateNotifications,
			getLocateClientNotifications,
		} = this.props
		const isLoggedIn = isAuthenticated()

		document.addEventListener('click', this.handleClickOutside)

		const theme = getThemeFromLs()
		setDarkTheme(theme === 'dark')
		setFlexMonsterTheme(theme)

		const suggestions = localStorage.getItem('suggestions') || 'hide'
		setSuggestions(suggestions === 'show')
		isLoggedIn && isExecutionNotificationPermission() && getExecutionNotifications()
		if ([CLIENT_CONSTANTS.FINOPTSYS, CLIENT_CONSTANTS.DH].includes(this.clientConfig)) {
			checkFeaturePermission(permission.LOCATE_PROVIDER) && getLocateNotifications()
			checkFeaturePermission(permission.LOCATE_SEEKER) && getLocateClientNotifications()
		}
	}

	componentDidUpdate(prevProps) {
		const {activeMenuData, navBarType} = this.props

		if (!Lodash.isEqual(prevProps.activeMenuData, activeMenuData)) {
			const menuOptions = !Lodash.isEmpty(activeMenuData) ? getMenuOptions(activeMenuData) : []
			const defaultMenuOptions = getDefaultAvailableMenuOptions()
			const navBarConfig =
				navBarType === 'main'
					? navigationMenu[Config.activeConfig.id]
					: ArrayUtil.isEmpty(menuOptions)
					? getNavBarConfig(defaultMenuOptions)
					: getNavBarConfig(menuOptions)
			this.setState({menuOptions, navBarConfig})
		}
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside)
	}

	handleClickOutside = event => {
		const domNode = ReactDOM.findDOMNode(this)

		if (!domNode || !domNode.contains(event.target)) {
			this.hideProfileOptions(event)
		}
	}

	hideProfileOptions = e => {
		e.stopPropagation()
		this.setState({
			showProfile: false,
		})
	}

	handleProfileOptions = e => {
		e.stopPropagation()
		this.setState({
			showProfile: !this.state.showProfile,
		})
	}

	goTo = path => {
		if (path === paths.abcmResult) {
			const {portfolioDetails} = this.props
			if (Lodash.isEmpty(portfolioDetails)) {
				this.props.getPortfolioUpdateDateTime().then(result => {
					if (result && result.response && result.response.status === 200) {
						history.push(paths.abcmResult, {
							portfolioDetails,
						})
					}
				})
			} else {
				history.push(paths.abcmResult, {
					portfolioDetails,
				})
			}
		} else {
			history && history.push(path)
		}
	}

	toggleNavbarType = () => {
		this.props.toggleNavbarType(!this.props.wideNavbar)
	}

	changeTheme = (theme: string) => {
		const {setDarkTheme} = this.props

		if (theme === 'dark') {
			localStorage.setItem('theme', 'light')
			setDarkTheme(false)
			setFlexMonsterTheme('light')
		} else {
			localStorage.setItem('theme', 'dark')
			setDarkTheme(true)
			setFlexMonsterTheme('dark')
		}
		document.getElementsByTagName('html')[0].setAttribute('data-theme', localStorage.getItem('theme'))
	}

	changeSuggestions = value => {
		const {setSuggestions} = this.props
		if (value) {
			localStorage.setItem('suggestions', 'show')
			setSuggestions(true)
		} else {
			localStorage.setItem('suggestions', 'hide')
			setSuggestions(false)
		}
	}

	handleLogout = () => {
		const {authLogout, clearTenantConfig} = this.props
		genericLogout(authLogout, history)
		clearTenantConfig()
	}

	showCustomiseMenu = () => {
		this.setState({showCustomiseMenu: true})
	}

	handleCustomiseMenuClose = () => {
		const {getActiveMenu} = this.props
		this.setState({showCustomiseMenu: false}, getActiveMenu)
	}

	manualScrolling = Lodash.debounce(topPosition => {
		const {navBarType} = this.props
		if (navBarType !== 'main') this.setState({scrollLevel: topPosition.scrollTop / 57})
	}, 500)

	render() {
		const {
			user = {},
			location: {pathname: activePath} = {pathname: ''},
			wideNavbar,
			baseCurrency,
			pfUploadOngoing,
			executionNotifications,
			navBarType,
			isTenantExternalIdp,
		} = this.props
		const {showCustomiseMenu, navBarConfig, scrollLevel} = this.state
		const {disableThemeSwitch, disableDefaultHomepage, disableDocumentation} = Config.activeConfig.navbar

		const theme = getThemeFromLs()
		const userName = getUserName({
			...user,
			email: this.username || (user && user.email),
		})
		const suggestions = localStorage.getItem('suggestions') || 'hide'

		const menuOptionCount = getMenuOptionsCount(
			disableThemeSwitch,
			this.isIntlUser,
			isTenantExternalIdp,
			navBarConfig,
			disableDocumentation
		)

		const filteredMenu = getFilteredPermissionMenu(navBarConfig)

		return (
			<div
				className={`${
					navBarType === 'main' ? (wideNavbar ? 'right-nav active' : 'right-nav') : 'left-nav'
				} loader-wrapper ${pfUploadOngoing ? '-loading' : ''}`}
				onClick={this.hideProfileOptions}
			>
				<div className='nav-items-wrapper'>
					{navBarType === 'main' && (
						<div
							className='right-menu-heading d-flex align-items-center cursor-pointer fix-h-67px'
							onClick={this.toggleNavbarType}
						>
							<div className='d-flex arrow close-burgerpy-1'>
								<IconRightMenuOpen />
							</div>
							{wideNavbar && <div className='ml-3 f-14 text-uppercase'>Collapse Menu</div>}
						</div>
					)}
					{navBarType === 'fav' && (
						<div className='logo-wrapper'>
							<span className='f-24 font-weight-bold text-grey-2 cursor-pointer'>FOS</span>
						</div>
					)}
					{navBarType === 'main' && (
						<span className='nav-item arrow open-burger cursor-pointer border-none' onClick={this.toggleNavbarType}>
							<div className='right-menu-wrapper text-grey-11'>
								<IconRightMenuOpen />
							</div>
						</span>
					)}
					{/* {navBarType === 'main' && (
						<span className='nav-item'>
							<button className='btn btn-secondary d-block px-3' onClick={() => this.goTo(paths.productMenu)}>
								Explore FinOptSys Modules
							</button>
						</span>
					)} */}
					{navBarType === 'fav' && (
						<div className='nav-currency pt-3 pb-4'>
							{baseCurrency && (
								<span className='label' title={wideNavbar ? '' : `Base Currency: ${baseCurrency}`}>
									{baseCurrency}
								</span>
							)}
						</div>
					)}

					<div className='h-100 d-flex flex-column justify-space-between overflow-x-hidden'>
						<PerfectScrollbar
							options={{suppressScrollX: true, useBothWheelAxes: false}}
							onScrollY={(e: any) => this.manualScrolling(e)}
						>
							<div className='flex-fill overflow-auto menu-items-list'>
								{filteredMenu.map(route => {
									const isValidRoute =
										route.subMenu && route.subMenu.length > 0
											? checkRouteArrayPermissions(route.subMenu)
											: checkRoutePermission(route)
									return isValidRoute ? (
										route.subMenu && route.subMenu.length > 0 ? (
											<SubRoute
												key={route.label}
												goTo={this.goTo}
												activePath={activePath}
												route={route}
												icon={route.navigationMenuIcon}
												wideNavbar={wideNavbar}
												toggleNavbarType={this.toggleNavbarType}
												executionNotifications={executionNotifications}
											/>
										) : (
											<Route
												scrollLevel={scrollLevel}
												count={route.count}
												navBarType={navBarType}
												key={route.label}
												goTo={this.goTo}
												activePath={activePath}
												label={route.labelTooltip || route.label}
												path={route.path}
												icon={route.navigationMenuIcon}
												wideNavbar={wideNavbar}
												toggleNavbarType={this.toggleNavbarType}
												executionNotifications={executionNotifications}
											/>
										)
									) : null
								})}
							</div>
						</PerfectScrollbar>
						{wideNavbar ? (
							<div className='d-flex finoptsys-menu-logo-horizontal'>
								<div className='d-flex flex-fill'>
									<MenuFinoptsysLogoHorizontal />
								</div>
								{!Config.activeConfig.navbar.disableHelpIcon && (
									<div className='d-flex suggestions-btn'>
										<FsOnOffSwitch
											id='suggestionSwitch'
											title='Help'
											checked={suggestions === 'show'}
											onChange={value => this.changeSuggestions(value)}
										/>
									</div>
								)}
							</div>
						) : (
							<div className='d-flex justify-content-center finoptsys-menu-logo px-2 py-3'>
								<MenuFinoptsysLogo />
							</div>
						)}
					</div>
				</div>
				{navBarType === 'main' && (
					<>
						<div className='user-detail'>
							<div className='media align-items-center'>
								<div className='media-left' onClick={this.handleProfileOptions}>
									<div id='profile-image'>{userName}</div>
								</div>
								<div className='media-right nav-profile-color w-150px' onClick={this.handleProfileOptions}>
									<div className='f-14 font-weight-600 d-flex align-items-center'>
										<span className='user-name' title={(this.props.user || {}).email || 'User'}>
											{(this.props.user || {}).email || 'User'}
										</span>
									</div>
								</div>
								{!disableDefaultHomepage && <Homepage wideNavbar={wideNavbar} />}
							</div>
						</div>
						<div
							className={`${wideNavbar ? 'wide-profile-options' : 'profile-options'} menu-option-${menuOptionCount}`}
						>
							<div className={`dropdown-menu mt-4 ${this.state.showProfile ? 'show' : ''}`}>
								{/* <a className='dropdown-item' role='button' onClick={this.showCustomiseMenu}>
									<span>
										<IconCustomizeMenu />
									</span>
									Customize Menu
								</a> */}
								{this.username && (
									/* eslint-disable-next-line */
									<a href='#' className='dropdown-item justify-content-between pr-0' role='button'>
										<span className='align-items-center'>
											<IconProfile />
											<span className='p-0 text-grey-10 pl-2'>{this.username}</span>
										</span>
									</a>
								)}
								{this.isIntlUser && checkRoutePermission(navBarConfig.accountSettings) && (
									<Link to={paths.accountSettings} className='dropdown-item' role='button'>
										<span>
											<IconAccontSettings />
										</span>
										Account Settings
									</Link>
								)}

								{this.isTenantSelectionAllowed && (
									<Link to={paths.tenant} className='dropdown-item' role='button'>
										<span>
											<ForwardBackwardIcon />
										</span>
										Select Tenant
									</Link>
								)}
								{!isTenantExternalIdp && (
									<Link to={paths.changePassword} className='dropdown-item' role='button'>
										<span>
											<IconChangePassword />
										</span>
										Change Password
									</Link>
								)}
								{!disableThemeSwitch && (
									/* eslint-disable-next-line */
									<a
										href='#'
										onClick={() => this.changeTheme(theme)}
										className='dropdown-item justify-content-between pr-0'
										role='button'
									>
										<span className='align-items-center'>
											<NightMode />
											<span className='p-0 text-grey-10 pl-2'>Dark Mode</span>
										</span>
										<span className={`ml-2 ${theme === 'light' ? 'd-none' : ''}`}>
											<ButtonCheck />
										</span>
									</a>
								)}
								{!disableDocumentation && (
									<>
										{/* eslint-disable-next-line */}
										<a href='https://www.google.com' target='_blank' className='dropdown-item' role='button'>
											<span>
												<SelectedFiles />
											</span>
											Venturi User Guide
										</a>
										{/* eslint-disable-next-line */}
										<a href='https://www.google.com' target='_blank' className='dropdown-item' role='button'>
											<span>
												<SelectedFiles />
											</span>
											Operational Procedure
										</a>
										{/* <Link
											to={{
												pathname: paths.pdfViewer,
												state: {
													filename: 'venturi-user-guide',
													title: 'Venturi User Guide',
												},
											}}
											className='dropdown-item'
											role='button'
										>
											<span>
												<SelectedFiles />
											</span>
											Venturi User Guide
										</Link>
										<Link
											to={{
												pathname: paths.pdfViewer,
												state: {
													filename: 'operational-procedure',
													title: 'Operational Procedure',
												},
											}}
											className='dropdown-item'
											role='button'
										>
											<span>
												<SelectedFiles />
											</span>
											Operational Procedure
										</Link> */}
									</>
								)}
								{!isTenantExternalIdp && (
									/* eslint-disable-next-line */
									<a href='#' onClick={this.handleLogout} className='dropdown-item' role='button'>
										<span>
											<IconLogout />
										</span>
										Logout
									</a>
								)}
								{process && process.env && process.env.REACT_APP_BUILD_ID && process.env.REACT_APP_BUILD_DATE ? (
									<div className='text-left cursor-default f-12 text-grey-13 ml-3 mt-2'>
										Build: {process.env.REACT_APP_BUILD_ID} <br />
										Date: {process.env.REACT_APP_BUILD_DATE}
									</div>
								) : null}
							</div>
						</div>
					</>
				)}
				{showCustomiseMenu && (
					<CustomiseMenu
						showCustomiseMenu={showCustomiseMenu}
						handleCustomiseMenuClose={this.handleCustomiseMenuClose}
					/>
				)}
			</div>
		)
	}
}
